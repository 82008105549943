import { modules } from '../subqueries/modules'
import { seo } from '../subqueries/seo'

export const articleContent = `
data {
  id
  attributes {
    __typename
    slug
    description
    publishedAt
    heroAlignment
    media {
      data {
        attributes {
          width
          height
          caption
          mime
          url
          hash
          ext
        }
      }
    }
    article_categories {
      data {
        id
      }
    }
    title
    ${modules}
    ${seo}
  }
}`