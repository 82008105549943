import { render, staticRenderFns } from "./RecipeTop.vue?vue&type=template&id=c9b67208&scoped=true&"
import script from "./RecipeTop.vue?vue&type=script&lang=js&"
export * from "./RecipeTop.vue?vue&type=script&lang=js&"
import style0 from "./RecipeTop.vue?vue&type=style&index=0&id=c9b67208&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9b67208",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hero: require('/vercel/path0/components/Hero.vue').default,Standfirst: require('/vercel/path0/components/Standfirst.vue').default,VideoPlayer: require('/vercel/path0/components/VideoPlayer.vue').default})
