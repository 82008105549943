import { modules } from '../subqueries/modules'
import { seo } from '../subqueries/seo'

export const productContent = `
data {
  id
  attributes {
    __typename
    slug
    title
    displaytitle
    batch
    available
    sku
    cost
    shop
    recipes {
      data {
        id
      }
    }
    packshot {
      data {
        attributes {
          width
          height
          caption
          mime
          url
          hash
          ext
        }
      }
    }
    hero {
      data {
        attributes {
          width
          height
          caption
          mime
          url
          hash
          ext
        }
      }
    }
    product_category {
      data {
        id
        attributes {
          title
        }
      }
    }
    description
    info
    ${modules}
    ${seo}
  }
}`