import { modules } from '../subqueries/modules'
import { seo } from '../subqueries/seo'

export const recipeContent = `
data {
  id
    attributes {
      __typename
      slug
      title
      info
      heroAlignment
      description
      products {
        data {
          id
        }
      }
      recipe_type {
        data {
          id
        }
      }
      recipe_method {
        data {
          id
        }
      }
      steps {
        ... on ComponentSharedStep {
          label
          time
        }
      }
      ingredients {
        ... on ComponentSharedIngredients {
          text
        }
      }
      gear {
        ... on ComponentSharedGear {
          text
        }
      }
      preptime
      servings
      media {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
          }
        }
      }
      video {
        data {
          attributes {
            width
            height
            caption
            mime
            url
            hash
            ext
            provider_metadata
            createdAt
            updatedAt
            size
          }
        }
      }
      ${modules}
      ${seo}
    }
  }`