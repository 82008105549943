import { modules } from '../subqueries/modules'
import { seo } from '../subqueries/seo'

export const eventContent = `
data {
  id
  attributes {
    __typename
    slug
    title
    date
    tickets
    info
    heroAlignment
    description
    media {
      data {
        attributes {
          width
          height
          caption
          mime
          url
          hash
          ext
        }
      }
    }
    event_category {
      data {
        id
        attributes {
          title
        }
      }
    }
    ${modules}
    ${seo}
  }
}`